import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';

import photo from '../assets/images/lourdes.jpg';
import photo1 from '../assets/images/lourdes1.jpg';
import photo2 from '../assets/images/lourdes2.jpg';
import photo3 from '../assets/images/lourdes3.jpg';
import photo4 from '../assets/images/lourdes4.jpg';
import bgMaster from '../assets/images/bg-masthead.jpg';

const AuntieLourdes = () => (
  <Layout>

    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">{config.heading}</h1>
          <h2 className="text-white-50 mx-auto mt-4 mb-5">
            <strong>Educator. Carer. Mother.</strong> <br /><br />For many, she was our <em>second mother</em> and benefactor. She helped with our education and also raised us. <b>She was a devout Catholic</b>. She prayed everyday, in almost every waking hour. Many personal 'divine interventions' have been manifested because of her powerful prayers. Auntie Lourdes lived to 96 years. We will miss her.
          </h2>
          <Scroll type="id" element="about">
            <a href="#about" className="btn btn-primary mr-4">
              Learn more
            </a>
          </Scroll>
        </div>
      </div>
    </header>

    <section id="about" className="projects-section bg-light">
      <div className="container">
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="col-xl-8 col-lg-7">
            <img className="img-fluid mb-3 mb-lg-0" src={photo} alt="" />
          </div>
          <div className="col-xl-4 col-lg-5">
            <div className="featured-text text-center text-lg-left">
              <h4>Remembrance</h4>
              <p className="text-black-50 mb-4">
                Auntie Lourdes died on December 26, 2020, in her home province of Ilocos Norte, Philippines. She died from hospital complications and of old age.
              </p>
              <p className="text-black-50 mb-4">
                <b>Funeral services</b> will be held at the home of Bala residence in Pinili, where she has been residing for the past six months.
              </p>
              <p className="text-black-50 mb-0">
              <b>Novena prayers</b> will be conducted over Facebook Live, every 7pm PST. <a href="https://www.facebook.com/permalink.php?story_fbid=1594909427387012&id=100006039105362" target="_blank">Follow this post</a> by Maria Acosta (Maricon) for more information.
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-6">
            <img className="img-fluid" src={photo3} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-left">
                  <hr className="d-none d-lg-block mb-4 ml-0" />
                  <h4 className="text-white">Origin Story</h4>
                  <p className="mb-0 text-white-50">
                    Auntie Lourdes was born on March 5, 1924 to proud parents Paulino and Trinidad Acosta. She grew up in Laoag City, Philippines with her siblings: Jose, Alberto, Luzon, Consuelo, Josie, Remedios, Laluz, and Estrella.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters">
          <div className="col-lg-6">
            <img className="img-fluid" src={photo4} alt="" />
          </div>
          <div className="col-lg-6 order-lg-first">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-right">
                  <hr className="d-none d-lg-block mb-4 mr-0" />
                  <h4 className="text-white">Her Profession</h4>
                  <p className="mb-0 text-white-50">
                    Auntie Lourdes received a degree in Education from Philippine Normal College and worked as a teacher. This inspired her other sisters to take up the same profession. Eventually, she was promoted as a District Supervisor for the Gabaldon Schools of Ilocos Norte. She was a great public speaker and served as mentor to many teachers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-6">
            <img className="img-fluid" src={photo1} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-left">
                  <hr className="d-none d-lg-block mb-4 ml-0" />
                  <h4 className="text-white">Her Legacy</h4>
                  <p className="mb-4 text-white-50">
                    While Auntie Lourdes did not have children of her own, she took on the responsibility of being a 'second mother', especially to the Ramayrats and Acostas. She was also a benefactor to many others by helping them fund their college education. Visit this <a href="https://www.facebook.com/media/set/?set=a.10102208224667171&type=3" target="_blank">photo album</a> to learn more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <footer className="bg-black small text-center text-white-50">
      <div className="container">
        Copyright &copy; 2020 | In Memory Of Auntie Lourdes
      </div>
    </footer>
    <script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5fe9330d8012dd00"></script>
  </Layout>
);

export default AuntieLourdes;
